import { useState,useEffect } from "react"
import '../Styles/Navbar.css'
import logo from '../Images/logo.png'
import { GiHamburgerMenu } from "react-icons/gi";
import {Link} from 'react-router-dom'
export default function Navbar() {
  const [screenWidth, setScreenWidth] = useState(window.innerWidth)
  const [toggleMenu, setToggleMenu] = useState(false)
  useEffect(() => {

    const changeWidth = () => {
      setScreenWidth(window.innerWidth);
    }

    window.addEventListener('resize', changeWidth)

  }, [])
  const toggleNav = () => {
    setToggleMenu(!toggleMenu)
    console.log(toggleMenu)
  }
  return (
    <>
    {/* <Hamburger toggled={toggleMenu} toggle={setToggleMenu} className="hamburger"/> */}

    <nav className="navigation">
        <div className="logo-text">
          <img className="logo-img" src={logo} />
          <Link to="/" className="logo">
            StockMato
          </Link>
        </div>
        
    {(toggleMenu || screenWidth > 705) && (
      
    <ul className="list">
    <li className="items" >
      <Link to="/" style={{textDecoration:"none"}} >Home</Link>
    </li>
    <li className="items" > 
      <Link to="" style={{textDecoration:"none"}} >About </Link>
    </li>
    <li className="items" >
      <Link to="" style={{textDecoration:"none"}} >Services</Link>
    </li>
    <li className="items" >
      <Link to="" style={{textDecoration:"none"}} >Blogs</Link>
    </li>
  </ul>
    )}

     <GiHamburgerMenu onClick={toggleNav} className="btn" size={30}/> 
  </nav>
  </>
  )
}