import React from "react";
import "../Styles/Introduce.css";
const Introduce = () => {
  return (
    <div className="int-box">
      <div className="sub-box">
        <h1>Who We Are?</h1>
        <p id="p0">
          Stockmato is an Edu platform to learn and earn from stock market.We
          have been in market place since 2011.In initial phase of our journey
          We have not only trained the Traders but also imparted financial
          literacy to children,under privileged and of course to those who came
          in our contacts and became a successful traders.
        </p>
      </div>
      <div className="sub-box">
        <h1>What we provide?</h1>
        <p id="p0">
          Our main focus is to train the untrained Traders and get them into the
          vast profitable market. We have a team of highly skilled, enthusiastic
          and intellectual staff who gives guidance on Stock market’s basic to
          complicated terms in simple and sophisticated way. We also deals in
          Nifty,BankNifty,Crudeoil,Natural Gas,Gold ,Silver etc
        </p>
      </div>
      <div className="sub-box">
        <h1>Why we are best?</h1>
        <p id="p0">
          Stockmato is the perfect platform to enhance your Technical and
          Fundamental skills of Share market. We will guide you the right path
          which helps you to understand the market better way. We are on mission
          to produce Traders not a gambler.
        </p>
      </div>
    </div>
  );
};

export default Introduce;
