// import React from "react";
import { useEffect, useState } from "react";
import carosal_1 from '../assets/carosal_1.jpg'
import carosal_2 from '../assets/carosal_2.jpg'
import carosal_3 from '../assets/carosal_3.jpg'
import carosal_4 from '../assets/carosal_4.jpg'
import carosal_5 from '../assets/carosal_5.jpg'
import "../Styles/Home.css";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import img1 from '../Images/1.jpg'
import img2 from '../Images/2.jpg'
import Introduce from "./Introduce";
const Home = () => {

  var settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 2,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <>
      <div className="home-container">
       <div className="home-sub-container">
          <Slider {...settings}>
           
                <div className="img-container" >
                  <img src={carosal_1} className="card-img" />                  
                </div>
              
                <div className="img-container" >
                  <img src={carosal_2} className="card-img" />                  
                </div>
              
                <div className="img-container" >
                  <img src={carosal_3} className="card-img" />                  
                </div>
              
                <div className="img-container" >
                  <img src={carosal_4} className="card-img" />                  
                </div>
              
                <div className="img-container" >
                  <img src={carosal_5} className="card-img" />                  
                </div>
                        
          </Slider>
        </div>
        </div>
        <Introduce/>
    </>
  );
};

export default Home;
