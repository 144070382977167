import './App.css';
import Navbar from './Components/Navbar';
import Blogitem from './Components/Blogitem';
import Home from './Components/Home';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Footer from './Components/Footer';

function App() {
  return (
    <>
    <Router>
    <Navbar/>

      <Routes>
        <Route exact path="/" element={<Home/>}/>
        {/* <Route exact path="/blogitem" element={<Blogitem/>}/> */}
      </Routes>
      <Footer/>
    </Router>
    </>
  );
}

export default App;
